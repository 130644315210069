import { AuthenticationService } from "./services/authentication-service";
import { Aurelia, autoinject, observable } from "aurelia-framework";

@autoinject()
export class AuthenticationPage {
    private _aurelia: Aurelia;
    private _authenticationService: AuthenticationService;
    isLoggingIn: boolean = false;

    @observable
    loginEmail: string = "";

    @observable
    loginPassword: string = "";

    loginError: string = "";

    constructor(aurelia, authenticationService: AuthenticationService) {
        this._aurelia = aurelia;
        this._authenticationService = authenticationService;
    }

    attached() {
        this.resetLoginDetails();
    }

    async login() {
        this.isLoggingIn = true;
        if (!this.loginEmail || !this.loginPassword) {
            this.loginError = "Please enter an email and password";
            this.isLoggingIn = false;
            return;
        }

        if (this.loginEmail && this.loginPassword)
            var loginResult = await this._authenticationService.loginAsync(
                this.loginEmail,
                this.loginPassword
            );

        console.log("Login Succeeded", loginResult);

        if (!loginResult) {
            this.loginError = "Invalid email or password";
            this.loginPassword = "";
        }
        if (loginResult == "Unknown User") {
            this.loginError = "Invalid email or password";
            this.loginPassword = "";
            this.isLoggingIn = false;
        }
        if (loginResult == "Login Successful") {
            this.isLoggingIn = false;
            this.loginEmail = "";
            this.loginPassword = "";
        }
    }

    resetLoginDetails() {
        this.loginPassword = "";
    }

    loginEmailChanged() {
        this.loginError = "";
    }
    loginPasswordChanged() {
        this.loginError = "";
    }
}
