import { bindable, bindingMode, autoinject } from "aurelia-framework";
import { FormattingService } from "services/formatting-service";

@autoinject
export class TextElement {
    _formattingService: FormattingService;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    text: string;

    @bindable isEditable: boolean;
    @bindable isEditing: boolean;

    @bindable color: string;
    @bindable sanitize: boolean = false;
    @bindable placeholder: string;

    constructor(formattingService: FormattingService) {
        this._formattingService = new FormattingService();
    }

    sanitizeName(name: string) {
        if (name == null) return;
        return this._formattingService.formatDisplayText(name);
    }
}
