import { customElement, bindable, computedFrom, bindingMode } from "aurelia-framework"

@customElement("phone-element")
export class PhoneElement {

	@bindable({ defaultBindingMode: bindingMode.twoWay })
	phoneNumber: number;

	@bindable({ defaultBindingMode: bindingMode.toView })
	isEditing: boolean;

	@bindable({ defaultBindingMode: bindingMode.toView })
	isEditable: boolean;

	@computedFrom('phoneNumber')
	get areaCode(): number {
		return Math.floor(this.phoneNumber / 10000000);
	}

	@computedFrom('phoneNumber')
	get firstPart(): string {
		return (Math.floor(this.phoneNumber / 10000) % 1000).toString().padStart(3, '0');
	}

	@computedFrom('phoneNumber')
	get lastPart(): string {
		return (this.phoneNumber % 10000).toString().padStart(4, '0');
	}

}
