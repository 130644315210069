import { Aurelia } from "aurelia-framework";
import * as environment from "../config/environment.json";
import { PLATFORM } from "aurelia-pal";
import { initialState } from "./services/state";
import { AuthenticationService } from "services/authentication-service";

export function configure(aurelia: Aurelia) {
	aurelia.use
		.standardConfiguration()
		.feature(PLATFORM.moduleName("resources/index"));

	// aurelia.use.developmentLogging(environment.debug ? "debug" : "warn");

	if (environment.testing) {
		aurelia.use.plugin(PLATFORM.moduleName("aurelia-testing"));
	}

	aurelia.use.plugin(PLATFORM.moduleName("aurelia-dialog"), { initialState });
	aurelia.use.plugin(PLATFORM.moduleName("aurelia-store"), { initialState });

	aurelia.start().then(() => {
		var isLoggedIn = AuthenticationService.isLoggedIn();

		// console.log(isLoggedIn)

		if (isLoggedIn) aurelia.setRoot(PLATFORM.moduleName("app"));
		else aurelia.setRoot(PLATFORM.moduleName("authentication-page"));
	});
}
