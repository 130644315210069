import { customElement, bindable, bindingMode } from "aurelia-framework";

@customElement("select-element")
export class SelectElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedOption: any;

    @bindable({ defaultBindingMode: bindingMode.toView })
    isEditing: boolean;

    @bindable({ defaultBindingMode: bindingMode.toView })
    isEditable: boolean;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    options: any;

    @bindable({ defaultBindingMode: bindingMode.fromView })
    displayKey: any;

    @bindable({ defaultBindingMode: bindingMode.fromView })
    displayInfoVariable: string;

    @bindable({ defaultBindingMode: bindingMode.fromView })
    noDataMessage?: string;

    @bindable({ defaultBindingMode: bindingMode.fromView })
    isRequired: boolean;

    menuIsOpen: boolean = false;

    toggleMenuState() {
        this.menuIsOpen = !this.menuIsOpen;
    }

    attached() {}

    bind() {
        console.log(this.isRequired);
    }

    selectOption(option) {
        this.selectedOption = option;
        this.menuIsOpen = false;
    }
    collapseDropdown() {
        this.menuIsOpen = false;
    }
}
